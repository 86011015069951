<template>
  <div>
    <div
      class="listing-full"
      :style="
        liningStyles(content) +
        `margin-top:${marginTop};margin-right:${
          landing.branding.lining_margin ? '10px' : '0'
        };margin-left:${landing.branding.lining_margin ? '10px' : '0'};min-height:300px;`
      "
      style="
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
      "
    >
      <div
        :style="`width: ${
          landing.branding.lining_margin ? '345px' : '360px'
        };height:${content.styles.img_height}px;overflow:hidden;`"
        v-show="content.img.includes('1')"
      >
        <div
          style="height: 100%; width: 330px; overflow: hidden"
          class="preview_detail"
        ></div>
      </div>
      <swiper
        ref="mySwiper"
        :direction="'horizontal'"
        :options="swiperOptionsListing"
        style="overflow: hidden;position: relative;"
        :style="`width: ${
          landing.branding.lining_margin ? '345px' : '360px'
        };height:${content.styles.img_height}px;`"
        v-if="content.img.length && !content.img.includes('1') || content.video"
        @slideChange="changeSwiperIndex"
      >
        <swiper-slide v-if="content.video">
          <video :width="landing.branding.lining_margin ? 345 : 360" :height="+content.styles.img_height" controls>
            <source :src="content.video" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </swiper-slide>
        <swiper-slide
          v-for="img in content.img"
          :key="img"
          style="width: 330px"
        >
          <img
            :src="img"
            alt=""
            :style="`width: ${
              landing.branding.lining_margin ? '345px' : '360px'
            };height:${content.styles.img_height}px;`"
          />
        </swiper-slide>
          <div class="swiper-pagination" slot="pagination" />
      </swiper>
      <div
        class="listing__play_video listing__play_video_with_img"
        :style="`top:${
          +content.styles.img_height / 2
        }px;position:absolute;z-index:100;`"
        v-if="content.video_link && content.img.length && activeIndex === 0"
        @click="openVideo(content.video_link)"
      ></div>
      <div
        class="listing__play_video listing__play_video_without_img"
        v-if="content.video_link && content.img.length === 0"
        @click="openVideo(content.video_link)"
      ></div>
      <div
              id="typeGalleryPreview"
        style="height: auto; min-height: 150px; width: 100%; padding: 15px;"
        v-html="htmlData(content.full_desc)"
      ></div>
      <FormOrderPreview :formOrder="content.form" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import FormOrderPreview from "./FormOrderPreview.vue";
import "swiper/swiper-bundle.css";
import "swiper/modules/pagination/pagination.min.css";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
SwiperCore.use([Pagination]);
export default {
  name: "typeGalleryPreview",
  components: {
    FormOrderPreview,
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapState(["landing"]),
    mySwiper: () => {
      return this.$refs.mySwiper.swiper;
    },
    marginTop(){
      if(this.content.img.length){
        return '0'
      }
      if(this.landing.advanced.global.active_header_inside ||
          this.landing.advanced.global.active_logo_inside){
        return '20px'
      }
      return '60px'
    }
  },
  props: ["content"],
  data() {
    return {
      activeIndex: 0,
      swiperOptionsListing: {
        grabCursor: true,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
    };
  },
  methods: {
    liningStyles(listing_item) {
      let styles;
      if (this.landing.branding.bg_lining_img) {
        styles = `background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
      } else {
        styles = `background-color:${this.landing.branding.bg_lining_color};`;
      }
      if (listing_item) {
        if (listing_item.img.length) {
          styles += `border-bottom-left-radius:${this.landing.branding.lining_radius}px;border-bottom-right-radius:${this.landing.branding.lining_radius}px;`;
        } else {
          styles += `border-radius:${this.landing.branding.lining_radius}px;`;
        }
      }
      return styles;
    },
    changeSwiperIndex() {
      this.activeIndex = this.$refs.mySwiper.$swiper.activeIndex;
    },
    openVideo(link) {
      window.open(link, "_blank");
    },
    timeNow() {
      this.datenow = moment().format("HH:mm");
      setInterval(() => {
        this.datenow = moment().format("HH:mm");
      }, 1000);
    },
    htmlData(data, type) {
      if (data) {
        const weatherInd = data.split(" ").indexOf(":weather:");
        let result = data.split(" ");
        if (weatherInd && this.landing.advanced.global.show_weather) {
          const newArr = result;
          newArr[weatherInd] =
            this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
              0
            ) + " ℃";
          result = newArr;
        }
        const timeInd = data.split(" ").indexOf(":time:");
        if (timeInd) {
          const newArr = result;
          newArr[timeInd] = this.datenow;
          result = newArr;
        }
        const dateInd = data.split(" ").indexOf(":date:");
        if (dateInd) {
          const newArr = result;
          newArr[dateInd] = this.dateToday;
          result = newArr;
        }
        const user_name = data.split(" ").indexOf(":name:");
        if (
          user_name &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_name] = "Agent";
          result = newArr;
        }
        const user_surname = data.split(" ").indexOf(":surname:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_surname] = "Smith";
          result = newArr;
        }
        const user_birthday = data.split(" ").indexOf(":birthday:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_birthday] = "24.03.1999";
          result = newArr;
        }
        const user_room = data.split(" ").indexOf(":room:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_room] = "707";
          result = newArr;
        }

        const user_auth = data.split(" ").indexOf(":auth:");
        if (user_auth && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_auth] = `<a href="#">Login</a>`;
          result = newArr;
        }
        const user_logout = data.split(" ").indexOf(":logout:");
        if (user_logout && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_logout] = `<a href="#">Logout</a>`;
          result = newArr;
        }
        return result.join(" ");
      }
    },
  },
};
</script>

<style lang="scss">
    #typeGalleryPreview ul{
        padding-left: 15px;
    }
</style>
